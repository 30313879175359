import { Code } from '../components/code.js';
import { CoreDemo } from '../components/core-demo.js';
import { ExtensionsDemo } from '../components/extensions-demo.js';
import { ExternalLink } from '../components/external-link.js';
import { Headline } from '../components/headline.js';
import { InlineCode } from '../components/inline-code.js';
import { Intro } from '../components/intro.js';
import { LogoCloud } from '../components/logo-cloud.js';
import { Meta } from '../components/meta.js';
import { Tabs } from '../components/tabs.js';
import { UtilitiesDemo } from '../components/utilities-demo.js';

export default function HomePage() {
  return (
    <>
      <Intro />
      <div className="mt-12 space-y-12 lg:mt-24 lg:space-y-24">
        <div className="space-y-4">
          <Headline>简介</Headline>
          <p>Jotai 采用 Atom 风格的方式进行全局的 React 状态管理。 </p>
          <p>
            通过组合 atom 来构建状态，并根据 atom 的依赖关系自动优化渲染。
            这解决了 React 上下文的额外重新渲染问题，消除了
            对 memoization 的需求，并在保持声明式编程模型的同时，
            提供了与信号（signals）类似的开发体验。
          </p>
          <p>
            它可以从简单的 <InlineCode>useState</InlineCode> 替代品扩展到
            具有复杂需求的企业级 TypeScript 应用程序。此外，还有大量实用工具和 
            扩展程序可以为你提供帮助！
          </p>
          <p>Jotai 已被应用于这些创新型公司的生产环境中。</p>
          <LogoCloud />
        </div>
        <div className="space-y-4">
          <Headline>入门</Headline>
          <p className="!mb-8">
            接下来将指导您创建一个简单的 Jotai 应用程序。首先是
            安装 Jotai，然后探索核心 API 的基础知识，最后在应用于 
            React 框架中进行服务器端渲染。
          </p>
          <Tabs tabs={gettingStartedTabs} />
        </div>
        <div className="space-y-4">
          <Headline>API 概述</Headline>
          <Tabs tabs={apiTabs} />
        </div>
        <div className="space-y-4">
          <Headline>了解更多</Headline>
          <p>观看由 Jotai 的创建者 Daishi 在 Egghead 上推出的免费课程。</p>
          <a
            href="https://egghead.io/courses/manage-application-state-with-jotai-atoms-2c3a29f0"
            target="_blank"
            rel="noreferrer"
            className="mt-4 block"
          >
            <img
              src="https://cdn.candycode.com/jotai/jotai-course-banner.jpg"
              className="block rounded-md shadow-lg dark:!shadow-none sm:rounded-lg"
              alt="Jotai course"
              title="Jotai course"
            />
          </a>
        </div>
      </div>
    </>
  );
}

const apiTabs = {
  Core: (
    <section>
      <h2>核心</h2>
      <p>
        Jotai 的 API 很精简，并且是面向 TypeScript 的。它与 React 
        内置的 <InlineCode>useState</InlineCode> hook 一样简单易用，并且所有状态都是全局可访问的，
        派生状态也易于实现，并且自动消除了不必要的重新渲染。
      </p>
      <CoreDemo />
    </section>
  ),
  Utilities: (
    <section>
      <h2>实用工具</h2>
      <p>
        Jotai 软件包还包含了一个 <InlineCode>jotai/utils</InlineCode> 包。这些额外的
        函数提供了在 localStorage 中持久化 atom、在服务器端渲染时hydrating an atom、
        使用类似 Redux 的 reducers 和 action types 创建 atom 等功能的
        支持。
      </p>
      <UtilitiesDemo />
    </section>
  ),
  Extensions: (
    <section>
      <h2>扩展</h2>
      <p>
        每个官方扩展都是独立的软件包：tRPC、Immer、Query、XState、
        URQL、Optics、Relay、location、molecules、cache 等。
      </p>
      <p>
        某些扩展提供了新的 atom 类型并自带写入函数，例如{' '}
        <InlineCode>atomWithImmer</InlineCode> (Immer) 或 <InlineCode>atomWithMachine</InlineCode>{' '}
        (XState)。
      </p>
      <p>
        其它新的 atom 类型提供了双向数据绑定函数，例如{' '}
        <InlineCode>atomWithLocation</InlineCode> 或 <InlineCode>atomWithHash</InlineCode>。
      </p>
      <ExtensionsDemo />
    </section>
  ),
};

const gettingStartedTabs = {
  'Installation': (
    <section>
      <h2>安装</h2>
      <p>首先将 Jotai 作为依赖项添加到 React 项目中。</p>
      <Code language="bash">{`# npm
npm i jotai

# yarn
yarn add jotai

# pnpm
pnpm add jotai
`}</Code>
    </section>
  ),
  'Create atoms': (
    <section>
      <h2>创建 atom</h2>
      <p>首先创建原始和派生类型的 atom 来构建状态。</p>
      <h3>原始类型的 atom</h3>
      <p>
        原始类型的 atom 可以是后面列出的任何类型：booleans、numbers、strings、objects、arrays、sets、maps、
        等。
      </p>
      <Code>{`import { atom } from 'jotai'

const countAtom = atom(0)

const countryAtom = atom('Japan')

const citiesAtom = atom(['Tokyo', 'Kyoto', 'Osaka'])

const animeAtom = atom([
  {
    title: 'Ghost in the Shell',
    year: 1995,
    watched: true
  },
  {
    title: 'Serial Experiments Lain',
    year: 1998,
    watched: false
  }
])`}</Code>
      <h3>派生类型的 atom</h3>
      <p>派生类型的 atom 在返回自身的值之前，可以读取其它 atom 的值。</p>
      <Code>{`const progressAtom = atom((get) => {
  const anime = get(animeAtom)
  return anime.filter((item) => item.watched).length / anime.length
})`}</Code>
    </section>
  ),
  'Use atoms': (
    <section>
      <h2>使用 atom</h2>
      <p>在 React 组件中使用 atom 来读取或写入状态。</p>
      <h3>从同一个组件中读取和写入</h3>
      <p>
        在同一个组件中读取和写入 atom 时，为简便起见，请使用合并后的{' '}
        <InlineCode>useAtom</InlineCode> hook。
      </p>
      <Code>{`import { useAtom } from 'jotai'

const AnimeApp = () => {
  const [anime, setAnime] = useAtom(animeAtom)

  return (
    <>
      <ul>
        {anime.map((item) => (
          <li key={item.title}>{item.title}</li>
        ))}
      </ul>
      <button onClick={() => {
        setAnime((anime) => [
          ...anime,
          {
            title: 'Cowboy Bebop',
            year: 1998,
            watched: false
          }
        ])
      }}>
        Add Cowboy Bebop
      </button>
    <>
  )
}`}</Code>
      <h3>从不同的组件中读取和写入</h3>
      <p>
        当 atom 的值为只允许读或写时，请使用{' '}
        <InlineCode>useAtomValue</InlineCode> 和 <InlineCode>useSetAtom</InlineCode> 这两个 hook 来
        优化重新渲染的效率。
      </p>
      <Code>{`import { useAtomValue, useSetAtom } from 'jotai'

const AnimeList = () => {
  const anime = useAtomValue(animeAtom)

  return (
    <ul>
      {anime.map((item) => (
        <li key={item.title}>{item.title}</li>
      ))}
    </ul>
  )
}

const AddAnime = () => {
  const setAnime = useSetAtom(animeAtom)

  return (
    <button onClick={() => {
      setAnime((anime) => [
        ...anime,
        {
          title: 'Cowboy Bebop',
          year: 1998,
          watched: false
        }
      ])
    }}>
      Add Cowboy Bebop
    </button>
  )
}

const ProgressTracker = () => {
  const progress = useAtomValue(progressAtom)

  return (
    <div>{Math.trunc(progress * 100)}% watched</div>
  )
}

const AnimeApp = () => {
  return (
    <>
      <AnimeList />
      <AddAnime />
      <ProgressTracker />
    </>
  )
}`}</Code>
    </section>
  ),
  'SSR': (
    <section>
      <h2>服务器端渲染</h2>
      <p>
        如果服务器端渲染使用的是类似{' '}
        <ExternalLink to="https://nextjs.org">Next.js</ExternalLink> 或{' '}
        <ExternalLink to="https://waku.gg">Waku</ExternalLink> 之类的框架时，请确保在 root 节点下
        添加一个 Jotai Provider。
      </p>
      <h3>Next.js （使用 app 目录）</h3>
      <p>
        在单独的客户端组件中创建 Jotai provider。然后将新建的 provider 导入（import）到根目录下的{' '}
        <InlineCode>layout.js</InlineCode> 服务器端组件中。
      </p>
      <Code>{`// ./components/providers.js
'use client'

import { Provider } from 'jotai'

export const Providers = ({ children }) => {
  return (
    <Provider>
      {children}
    </Provider>
  )
}


// ./app/layout.js
import { Providers } from '../components/providers'

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <body>
        <Providers>
          {children}
        </Providers>
      </body>
    </html>
  )
}
`}</Code>
      <h3>Next.js （使用 pages 目录）</h3>
      <p>
        在 <InlineCode>_app.js</InlineCode> 中添加 Jotai provider。
      </p>
      <Code>{`// ./pages/_app.js
import { Provider } from 'jotai'

export default function App({ Component, pageProps }) {
  return (
    <Provider>
      <Component {...pageProps} />
    </Provider>
  )
}
`}</Code>
      <h3>Waku</h3>
      <p>
        在单独的客户端组件中创建 Jotai provider。然后将新建的 provider 导入（import）到根
        布局文件中。
      </p>
      <Code>{`// ./src/components/providers.js
'use client'

import { Provider } from 'jotai'

export const Providers = ({ children }) => {
  return (
    <Provider>
      {children}
    </Provider>
  )
}


// ./src/pages/_layout.js
import { Providers } from '../components/providers'

export default async function RootLayout({ children }) {
  return (
    <Providers>
      {children}
    </Providers>
  )
}
`}</Code>
    </section>
  ),
};

export const Head = () => {
  return <Meta />;
};
